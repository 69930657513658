import React from 'react';
import { useReducer, createContext } from 'react';

export const CountryContext = createContext();

const initialState = {
    country: 'us',
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'set_country':
            return {
                ...state,
                country: action.country,
            };

        default:
            return state;
    }
};

export const CountryContextProvider = (props) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <CountryContext.Provider
            value={{
                state,
                dispatch,
            }}
        >
            {props.children}
        </CountryContext.Provider>
    );
};
