import { useContext } from 'react';
import { LoaderContext } from '../context';

export const useLoader = () => {
    const { loaderDispatch } = useContext(LoaderContext);
    return async (serviceFunc) => {
        let result = null;
        try {
            loaderDispatch({
                type: 'enable',
            });
            result = await serviceFunc;
        } finally {
            loaderDispatch({
                type: 'disable',
            });
        }
        return result;
    };
};
