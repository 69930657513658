export const navLinks = [
    {
        href: '/verify',
        name: 'Verify',
    },
    {
        href: '/autocomplete',
        name: 'Autocomplete',
    },
    {
        href: '/parse',
        name: 'Parse',
    },
];
