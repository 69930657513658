import React from 'react';
import { ButtonGroup, Button, makeStyles } from '@material-ui/core';
import { useRouter } from '../common';
import { navLinks } from './constants';

import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    appBar: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    navItem: {
        flexGrow: 1,
        textAlign: 'center',
        textTransform: 'none',
        fontSize: '1em',
        padding: theme.spacing(1),
        cursor: 'pointer',
        border: '1px solid #ddd',
    },
    active: {
        color: '#fff',
        'background-image': 'linear-gradient(to top, #4d8afb, #2569e6)',
    },
}));

export default function TopNav() {
    const classes = useStyles();
    const { history } = useRouter();
    const location = useLocation();

    return (
        <ButtonGroup className={classes.appBar}>
            {navLinks.map((navItem) => {
                return (
                    <Button
                        className={`${classes.navItem} ${
                            navItem.href == location.pathname
                                ? classes.active
                                : ''
                        }`}
                        key={navItem.name}
                        onClick={() => {
                            history.push(navItem.href);
                        }}
                    >
                        {navItem.name}
                    </Button>
                );
            })}
        </ButtonGroup>
    );
}
