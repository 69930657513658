import { Button, makeStyles, MenuItem, Select } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import {
    countryCodeDisplayName,
    errorCodesToMessages,
    getCommonFormClasses,
    StructedAddress,
    useLoader,
    useRequest,
    validCodeToStatus,
    sampleAddresses,
} from '../common';
import { FreeFormAddress } from '../common/address';
import { FlashMessageContext } from '../context';
import * as services from './services';
import TopNav from '../top-nav';

const useStyles = makeStyles((theme) => ({
    flexWrapper: {
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    flexItem: {
        flexGrow: 1,
        width: '100%',
    },
    ...getCommonFormClasses(theme),
}));

export default () => {
    const classes = useStyles();
    const [mode, updateMode] = useState('structured');
    const [address, setAddress] = useState({});
    const [response, setResponse] = useState(null);
    const [inputAddress, setInputAddress] = useState('');
    const loader = useLoader();
    const request = useRequest();
    const { messageDispatch } = useContext(FlashMessageContext);
    const [selectedResponse, updateSelection] = useState(null);
    useEffect(() => {
        if (mode === 'structured') {
            setAddress({});
        } else {
            setAddress('');
        }
    }, [mode]);

    const suggestAddresses = async () => {
        if (mode === 'structured' && !address.address) {
            messageDispatch({
                type: 'error',
                payload: {
                    message: 'Address Line 1 is required',
                },
            });
            return;
        } else if (mode === 'freeform' && !address) {
            messageDispatch({
                type: 'error',
                payload: {
                    message: 'Address value is required',
                },
            });
            return;
        }
        const res = await loader(
            services.suggestions(
                request,
                mode === 'structured' ? address : { address }
            )
        );

        if (mode === 'structured') {
            setInputAddress(services.parseInputAddressToString(address));
        } else {
            setInputAddress(address);
        }

        let parsedResponse =
            res.suggestions.map((suggestion) => {
                return {
                    key: services.parseResponseAddressToString(suggestion),
                    value: suggestion,
                };
            }) || [];
        parsedResponse.length > 0
            ? updateSelection(parsedResponse[0].value)
            : updateSelection(null);
        setResponse(parsedResponse);
    };

    return (
        <>
            <TopNav />
            <div className={classes.flexWrapper}>
                <div className={classes.flexItem}>
                    <div className={classes.paddingLarge}>
                        <div className={classes.marginBottomLarge}>
                            Get address suggestions across US and Canada.
                        </div>
                        <div className={classes.formItemWrapper}>
                            <div className={classes.label}>Type</div>
                            <div className={classes.inputWrapper}>
                                <Select
                                    className={classes.select}
                                    value={mode}
                                    onChange={(ev) => {
                                        updateMode(ev.target.value);
                                    }}
                                    variant="outlined"
                                >
                                    <MenuItem value={'structured'}>
                                        Structured
                                    </MenuItem>
                                    <MenuItem value={'freeform'}>
                                        Free Form
                                    </MenuItem>
                                </Select>
                            </div>
                        </div>
                        {mode === 'structured' ? (
                            <StructedAddress
                                address={address}
                                setAddress={setAddress}
                                itemWrapperClass={classes.formItemWrapper}
                                labelClass={classes.label}
                                inputWrapperClass={classes.inputWrapper}
                                selectClass={classes.select}
                                inputClass={classes.input}
                            />
                        ) : (
                            <FreeFormAddress
                                address={address}
                                setAddress={setAddress}
                                itemWrapperClass={classes.formItemWrapper}
                                labelClass={classes.label}
                                inputWrapperClass={classes.inputWrapper}
                                inputClass={classes.input}
                            />
                        )}
                        <div className={classes.textCenter}>
                            <Button
                                className={classes.secondaryButton}
                                onClick={() => {
                                    setAddress(
                                        sampleAddresses[
                                            address.country || 'us'
                                        ][mode]
                                    );
                                }}
                            >
                                Sample
                            </Button>
                            <Button
                                className={classes.primaryButton}
                                onClick={() => {
                                    suggestAddresses();
                                }}
                            >
                                Suggest
                            </Button>
                        </div>
                    </div>
                </div>
                <div className={classes.flexItem}>
                    <div
                        className={`${classes.rightWrapper} ${classes.paddingLarge}`}
                    >
                        {response ? (
                            <div>
                                <div className={classes.boxWrapper}>
                                    <div className="heading">Entered</div>
                                    <div className="subText">
                                        {inputAddress}
                                    </div>
                                </div>
                                <div className={classes.boxWrapper}>
                                    <div className="heading">Result</div>
                                    <div className="selectResponseWrapper">
                                        {response.length > 0 ? (
                                            <Select
                                                className="selectResponse"
                                                value={selectedResponse}
                                                onChange={(ev) => {
                                                    updateSelection(
                                                        ev.target.value
                                                    );
                                                }}
                                                variant="outlined"
                                            >
                                                {response.map((suggestion) => {
                                                    return (
                                                        <MenuItem
                                                            key={suggestion.key}
                                                            value={
                                                                suggestion.value
                                                            }
                                                        >
                                                            {suggestion.key}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        ) : (
                                            'No suggestions found'
                                        )}
                                    </div>

                                    {selectedResponse && (
                                        <div className="subText">
                                            {address.address && (
                                                <div className="addressLine">
                                                    <b>Address Line 1:</b>{' '}
                                                    {selectedResponse.address}
                                                </div>
                                            )}
                                            {selectedResponse.city && (
                                                <div className="addressLine">
                                                    <b>City:</b>{' '}
                                                    {selectedResponse.city}
                                                </div>
                                            )}
                                            {selectedResponse.provState && (
                                                <div className="addressLine">
                                                    <b>
                                                        {selectedResponse.country ===
                                                        'CA'
                                                            ? 'Province'
                                                            : 'State'}
                                                        :
                                                    </b>{' '}
                                                    {selectedResponse.provState}
                                                </div>
                                            )}
                                            {address.postalZip && (
                                                <div className="addressLine">
                                                    <b>
                                                        {address.country ===
                                                        'CA'
                                                            ? 'Postal Code'
                                                            : 'Zip Code'}
                                                        :
                                                    </b>{' '}
                                                    {address.postalZip}
                                                </div>
                                            )}
                                            {address.country && (
                                                <div className="addressLine">
                                                    <b>Country:</b>{' '}
                                                    {countryCodeDisplayName(
                                                        address.country
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        ) : (
                            'Enter an address to get suggestions.'
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};
