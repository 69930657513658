import { Button, makeStyles } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import {
    AutoCompleteSearchBox,
    countryCodeDisplayName,
    getCommonFormClasses,
    useLoader,
    useRequest,
    errorCodesToMessages,
} from '../common';
import { FlashMessageContext } from '../context';
import * as services from './services';
import TopNav from '../top-nav';

const useStyles = makeStyles((theme) => ({
    flexWrapper: {
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    flexItem: {
        flexGrow: 1,
        width: '100%',
    },
    ...getCommonFormClasses(theme),
}));

export default () => {
    const classes = useStyles();
    const [address, setAddress] = useState(null);
    const [textValue, setTextValue] = useState('');
    const loader = useLoader();
    const request = useRequest();
    const abortController = new AbortController();

    const autoCompleteRequest = async () => {
        if (!textValue) {
            return;
        }
        const res = await loader(
            services.completions(request, { partialStreet: textValue })
        );
        const completions = res.completions;
        return completions;
    };

    return (
        <>
            <TopNav />
            <div className={classes.flexWrapper}>
                <div className={classes.flexItem}>
                    <div className={classes.paddingLarge}>
                        <div className={classes.marginBottomLarge}>
                            Autocomplete addresses across US and Canada.
                        </div>
                        <AutoCompleteSearchBox
                            value={address}
                            setValue={setAddress}
                            inputValue={textValue}
                            setInputValue={setTextValue}
                            optionsRequest={autoCompleteRequest}
                            getOptionLabel={services.parseAddressToString}
                            abortController={abortController}
                            autoCompleteLabel={'Start typing an address here'}
                        />
                    </div>
                </div>

                <div className={classes.flexItem}>
                    <div
                        className={`${classes.rightWrapper} ${classes.paddingLarge}`}
                    >
                        {address ? (
                            <div>
                                <div className={classes.boxWrapper}>
                                    <div className="heading">
                                        Selected Address
                                    </div>
                                    <div className="subText">
                                        {address.address && (
                                            <div className="addressLine">
                                                <b>Address Line 1:</b>{' '}
                                                {address.address}
                                            </div>
                                        )}
                                        {address.city && (
                                            <div className="addressLine">
                                                <b>City:</b> {address.city}
                                            </div>
                                        )}
                                        {address.prov && (
                                            <div className="addressLine">
                                                <b>
                                                    {address.country === 'CA'
                                                        ? 'Province'
                                                        : 'State'}
                                                    :
                                                </b>{' '}
                                                {address.prov}
                                            </div>
                                        )}
                                        {address.pc && (
                                            <div className="addressLine">
                                                <b>
                                                    {address.country === 'CA'
                                                        ? 'Postal Code'
                                                        : 'Zip Code'}
                                                    :
                                                </b>{' '}
                                                {address.pc}
                                            </div>
                                        )}
                                        {address.country && (
                                            <div className="addressLine">
                                                <b>Country:</b>{' '}
                                                {countryCodeDisplayName(
                                                    address.country
                                                )}
                                            </div>
                                        )}
                                        {address.correctionCode && (
                                            <ul>
                                                {errorCodesToMessages(
                                                    address.correctionCode
                                                ).map((res) => {
                                                    return (
                                                        <li key={res.key}>
                                                            {res.value}
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ) : (
                            'Enter an address in the textbox to get autocomplete suggestions.'
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};
