import React from 'react';
import { CircularProgress, makeStyles } from '@material-ui/core';

export const LoaderContext = React.createContext();

const initialState = {
    enabled: false,
};

const useStyles = makeStyles((theme) => ({
    circularProgress: {
        zIndex: theme.zIndex.modal + 1,
        position: 'fixed',
        top: '50%',
        left: '50%',
    },
}));

const reducer = (state, action) => {
    switch (action.type) {
        case 'enable':
            return {
                ...state,
                enabled: true,
            };
        case 'disable':
            return {
                ...state,
                enabled: false,
            };
        default:
            return state;
    }
};

export const LoaderContextProvider = (props) => {
    const classes = useStyles();
    const [loaderState, loaderDispatch] = React.useReducer(
        reducer,
        initialState
    );
    return (
        <LoaderContext.Provider
            value={{
                loaderState,
                loaderDispatch,
            }}
        >
            {loaderState.enabled && (
                <CircularProgress className={classes.circularProgress} />
            )}
            {props.children}
        </LoaderContext.Provider>
    );
};
