export const validCodeToStatus = (code) => {
    switch (code) {
        case 'V':
            return 'Verified';
        case 'C':
            return 'Corrected';
        default:
            return 'Failed';
    }
};

const getChangeReason = (value) => {
    switch (value) {
        case 'A':
            return 'Alternate';
        case 'F':
            return 'Would not fit';
        case 'I':
            return 'Incorrect Value';
        case 'M':
            return 'Missing Value';
        case 'R':
            return 'Rural validated on postal code only';
        case 'T':
            return 'Typo in Value';
        case 'V':
            return 'Verbosity';
    }
};

const getChangedComponent = (value) => {
    switch (value) {
        case '01':
            return 'Addressee and/or Additional Delivery Information';
        case '10':
            return 'Complete Street Information';
        case '11':
            return 'Civic number';
        case '12':
            return 'Civic number suffix';
        case '13':
            return 'Street name';
        case '14':
            return 'Street type';
        case '15':
            return 'Street direction';
        case '16':
            return 'Suite keyword';
        case '17':
            return 'Suite identifier';
        case '19':
            return 'Delivery Information';
        case '20':
            return 'Complete Route Service Information';
        case '22':
            return 'Route Service Keyword';
        case '24':
            return 'Route Service identifier';
        case '30':
            return 'Complete PO Box/Bag Information';
        case '32':
            return 'PO Box/Bag keyword';
        case '34':
            return 'PO Box/Bag identifier';
        case '40':
            return 'Complete General Delivery Information';
        case '42':
            return 'General Delivery keyword';
        case '50':
            return 'Complete Delivery Installation Information';
        case '52':
            return 'Delivery Installation Area Name';
        case '54':
            return 'Delivery Installation Type';
        case '56':
            return 'Delivery Installation Qualifier';
        case '60':
            return 'Municipality';
        case '62':
            return 'Province';
        case '64':
            return 'Postal Code';
        default:
            return 'Unknown Component Changed';
    }
};

export const errorCodesToMessages = (code) => {
    let results = [];
    if (code && code.length) {
        for (let i = 0; i < code.length; i = i + 3) {
            let value = code.slice(i, i + 3);
            let numeric = value.slice(0, 2);
            let alpha = value.slice(2, 3);
            results.push({
                key: value,
                value: `${getChangeReason(alpha)}: ${getChangedComponent(
                    numeric
                )}`,
            });
        }
    }
    return results;
};
