export const getCommonFormClasses = (theme) => {
    return {
        select: {
            '&>.MuiSelect-root': {
                paddingLeft: theme.spacing(1),
                paddingTop: theme.spacing(1),
                paddingBottom: theme.spacing(1),
            },
            width: '100%',
            '&:before': {
                borderBottom: 0,
                transition: 'none',
            },
            '&:after': {
                borderBottom: 0,
                transition: 'none',
            },
            backgroundColor: '#f8fafa',
            border: 'solid 1px #e4e8ea',
            borderRadius: '4px',
            '&.MuiInput-underline:hover:not(.Mui-disabled):before': {
                borderBottom: 0,
                transition: 'none',
            },
            '&>.MuiSelect-select:focus': {
                background: 'none',
            },
            '&.MuiInputBase-root>fieldset': {
                border: 0,
            },
        },
        label: {
            width: '100%',
            fontWeight: 'bold',
        },
        inputWrapper: {
            width: '200%',
        },
        formItemWrapper: {
            display: 'flex',
            alignItems: 'center',
            margin: theme.spacing(1, 0),
        },
        marginBottomLarge: {
            marginBottom: theme.spacing(4),
        },
        input: {
            width: '100%',
            '&>.MuiInputBase-root>.MuiInputBase-input': {
                padding: theme.spacing(1),
            },
            '& input': {
                borderRadius: '4px',
                backgroundColor: '#f8fafa',
                border: 'solid 1px #e4e8ea',
                boxShadow: 'none',
            },
            '& label': {
                transform: 'translate(10px,10px)',
            },
            '&>.MuiInput-underline:before, .MuiInput-underline:after, .MuiInput-underline:hover:not(.Mui-disabled):before': {
                borderBottom: 0,
                transition: 'none',
            },
            '&>.MuiInputBase-root.Mui-disabled': {
                color: '#000',
            },
            '&>.MuiInputBase-root': {
                '&>.MuiOutlinedInput-notchedOutline': {
                    border: '0',
                },
            },
        },
        textCenter: {
            textAlign: 'center',
        },
        secondaryButton: {
            background: '#fff',
            color: '#000',
            margin: theme.spacing(3, 1),
            padding: theme.spacing(1.25, 4),
            border: 'solid 1px #e0e5eb',
            fontSize: 14,
            borderRadius: theme.spacing(4),
            '&:hover': {
                background: '#fff',
                boxShadow: 'none',
            },
            textTransform: 'none',
            boxShadow: 'none',
        },
        primaryButton: {
            background: '#296de8',
            backgroundImage: 'linear-gradient(to top, #4d8afb, #2569e6)',
            color: '#fff',
            margin: theme.spacing(3, 1),
            padding: theme.spacing(1.25, 4),
            fontSize: 14,
            borderRadius: theme.spacing(4),
            '&:hover': {
                background: '#2569e6',
                boxShadow: 'none',
            },
            textTransform: 'none',
            boxShadow: 'none',
        },
        rightWrapper: {
            display: 'flex',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
        },
        boxWrapper: {
            border: '1px solid #ddd',
            marginBottom: theme.spacing(2),
            width: '100%',
            minWidth: '350px',
            '&>.heading': {
                padding: theme.spacing(2),
                borderBottom: '1px solid #ddd',
                fontWeight: 'bold',
            },
            '&>.subText': {
                padding: theme.spacing(2),
                '&>.status': {
                    '&.Verified': {
                        color: 'green',
                    },
                    '&.Corrected': {
                        color: 'blue',
                    },
                    '&.Failed': {
                        color: 'red',
                    },
                },
                '&>.address': {
                    marginBottom: theme.spacing(2),
                },
                '&>.addressLine': {
                    marginBottom: theme.spacing(1),
                },
            },
            '&>.selectResponseWrapper': {
                padding: theme.spacing(1),
                '&>.selectResponse': {
                    '&>.MuiSelect-root': {
                        paddingLeft: theme.spacing(1),
                        paddingTop: theme.spacing(1),
                        paddingBottom: theme.spacing(1),
                    },
                    width: '100%',
                },
            },
        },
        paddingLarge: {
            padding: theme.spacing(4),
            [theme.breakpoints.down('sm')]: {
                padding: theme.spacing(2),
            },
        },
    };
};
