import React from 'react';
import { MenuItem, Select, TextField } from '@material-ui/core';

const DEFAULT_COUNTRY_CODE = 'us';

export const StructedAddress = (props) => {
    const {
        address,
        setAddress,
        itemWrapperClass,
        labelClass,
        inputWrapperClass,
        selectClass,
        inputClass,
    } = props;

    return (
        <>
            <div className={itemWrapperClass}>
                <div className={labelClass}>Country</div>
                <div className={inputWrapperClass}>
                    <Select
                        className={selectClass}
                        value={address.country || ''}
                        onChange={(ev) => {
                            setAddress({
                                ...address,
                                country: ev.target.value,
                            });
                        }}
                        variant="outlined"
                    >
                        <MenuItem value={'us'}>United States</MenuItem>
                        <MenuItem value={'ca'}>Canada</MenuItem>
                    </Select>
                </div>
            </div>
            <div className={itemWrapperClass}>
                <div className={labelClass}>Line 1</div>
                <div className={inputWrapperClass}>
                    <TextField
                        required
                        variant="outlined"
                        type={'text'}
                        className={inputClass}
                        value={address.address || ''}
                        onChange={(ev) => {
                            setAddress({
                                ...address,
                                address: ev.target.value,
                            });
                        }}
                    />
                </div>
            </div>
            <div className={itemWrapperClass}>
                <div className={labelClass}>Line 2</div>
                <div className={inputWrapperClass}>
                    <TextField
                        variant="outlined"
                        type={'text'}
                        className={inputClass}
                        value={address.address2 || ''}
                        onChange={(ev) => {
                            setAddress({
                                ...address,
                                address2: ev.target.value,
                            });
                        }}
                    />
                </div>
            </div>
            <div className={itemWrapperClass}>
                <div className={labelClass}>City</div>
                <div className={inputWrapperClass}>
                    <TextField
                        variant="outlined"
                        type={'text'}
                        className={inputClass}
                        value={address.city || ''}
                        onChange={(ev) => {
                            setAddress({ ...address, city: ev.target.value });
                        }}
                    />
                </div>
            </div>
            <div className={itemWrapperClass}>
                <div className={labelClass}>
                    {address.country === 'ca' ? 'Province' : 'State'}
                </div>
                <div className={inputWrapperClass}>
                    <TextField
                        variant="outlined"
                        type={'text'}
                        className={inputClass}
                        value={address.prov || ''}
                        onChange={(ev) => {
                            setAddress({ ...address, prov: ev.target.value });
                        }}
                    />
                </div>
            </div>
            <div className={itemWrapperClass}>
                <div className={labelClass}>
                    {address.country === 'ca' ? 'Postal Code' : 'Zip Code'}
                </div>
                <div className={inputWrapperClass}>
                    <TextField
                        variant="outlined"
                        type={'text'}
                        className={inputClass}
                        value={address.postal || ''}
                        onChange={(ev) => {
                            setAddress({ ...address, postal: ev.target.value });
                        }}
                    />
                </div>
            </div>
        </>
    );
};

export const FreeFormAddress = (props) => {
    const {
        address,
        setAddress,
        itemWrapperClass,
        labelClass,
        inputWrapperClass,
        inputClass,
    } = props;
    return (
        <div className={itemWrapperClass}>
            <div className={labelClass}>Address</div>
            <div className={inputWrapperClass}>
                <TextField
                    variant="outlined"
                    type={'text'}
                    className={inputClass}
                    value={address}
                    onChange={(ev) => {
                        setAddress(ev.target.value);
                    }}
                />
            </div>
        </div>
    );
};
