import { countryCodeDisplayName } from '../common';

export const verify = async (request, params = {}) => {
    return await request('/verifications', 'post', params);
};

export const parseResponseAddressToString = (value) => {
    const { address, address2, city, provState, postalZip, country } = value;
    return [address, address2, city, provState, postalZip, country]
        .filter((elem) => !!elem)
        .join(' ');
};

export const parseInputAddressToString = (value) => {
    const { country, address, address2, city, prov, postal } = value;
    return [
        address,
        address2,
        city,
        prov,
        postal,
        countryCodeDisplayName(country),
    ]
        .filter((elem) => !!elem)
        .join(' ');
};
