import { Button, makeStyles, MenuItem, Select } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import {
    countryCodeDisplayName,
    getCommonFormClasses,
    StructedAddress,
    useLoader,
    useRequest,
    sampleAddresses,
} from '../common';
import { FreeFormAddress } from '../common/address';
import { FlashMessageContext } from '../context';
import * as services from './services';
import TopNav from '../top-nav';

const useStyles = makeStyles((theme) => ({
    flexWrapper: {
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    flexItem: {
        flexGrow: 1,
        width: '100%',
    },
    ...getCommonFormClasses(theme),
}));

export default () => {
    const classes = useStyles();
    const [address, setAddress] = useState('');
    const [inputAddress, setInputAddress] = useState('');
    const [response, setResponse] = useState(null);
    const loader = useLoader();
    const request = useRequest();
    const { messageDispatch } = useContext(FlashMessageContext);

    const ParseAddress = async () => {
        if (!address) {
            messageDispatch({
                type: 'error',
                payload: {
                    message: 'Address value is required',
                },
            });
            return;
        }
        const res = await loader(services.parse(request, { address: address }));
        setInputAddress(address);
        setResponse(res);
    };
    return (
        <>
            <TopNav />
            <div className={classes.flexWrapper}>
                <div className={classes.flexItem}>
                    <div className={classes.paddingLarge}>
                        <div className={classes.marginBottomLarge}>
                            Extract components from freeform addresses across
                            the world.
                        </div>
                        <FreeFormAddress
                            address={address}
                            setAddress={setAddress}
                            itemWrapperClass={classes.formItemWrapper}
                            labelClass={classes.label}
                            inputWrapperClass={classes.inputWrapper}
                            inputClass={classes.input}
                        />
                        <div className={classes.textCenter}>
                            <Button
                                className={classes.secondaryButton}
                                onClick={() => {
                                    setAddress(sampleAddresses.us.freeform);
                                }}
                            >
                                Sample
                            </Button>
                            <Button
                                className={classes.primaryButton}
                                onClick={() => {
                                    ParseAddress();
                                }}
                            >
                                Parse
                            </Button>
                        </div>
                    </div>
                </div>
                <div className={classes.flexItem}>
                    <div
                        className={`${classes.rightWrapper} ${classes.paddingLarge}`}
                    >
                        {response ? (
                            <div>
                                <div className={classes.boxWrapper}>
                                    <div className="heading">Entered</div>
                                    <div className="subText">
                                        {inputAddress}
                                    </div>
                                </div>
                                <div className={classes.boxWrapper}>
                                    <div className="heading">Result</div>
                                    {Object.keys(response).length > 0 ? (
                                        <div className="subText">
                                            {response.house_number && (
                                                <div className="addressLine">
                                                    <b>House Number:</b>{' '}
                                                    {response.house_number}
                                                </div>
                                            )}
                                            {response.house && (
                                                <div className="addressLine">
                                                    <b>House:</b>{' '}
                                                    {response.house}
                                                </div>
                                            )}
                                            {response.near && (
                                                <div className="addressLine">
                                                    <b>Near:</b> {response.near}
                                                </div>
                                            )}
                                            {response.unit && (
                                                <div className="addressLine">
                                                    <b>Unit:</b> {response.unit}
                                                </div>
                                            )}
                                            {response.category && (
                                                <div className="addressLine">
                                                    <b>Category:</b>{' '}
                                                    {response.category}
                                                </div>
                                            )}
                                            {response.level && (
                                                <div className="addressLine">
                                                    <b>Level:</b>{' '}
                                                    {response.level}
                                                </div>
                                            )}
                                            {response.staircase && (
                                                <div className="addressLine">
                                                    <b>Staircase:</b>{' '}
                                                    {response.staircase}
                                                </div>
                                            )}
                                            {response.entrance && (
                                                <div className="addressLine">
                                                    <b>Entrance:</b>{' '}
                                                    {response.entrance}
                                                </div>
                                            )}
                                            {response.road && (
                                                <div className="addressLine">
                                                    <b>Road:</b> {response.road}
                                                </div>
                                            )}
                                            {response.po_box && (
                                                <div className="addressLine">
                                                    <b>PO Box:</b>{' '}
                                                    {response.po_box}
                                                </div>
                                            )}
                                            {response.postcode && (
                                                <div className="addressLine">
                                                    <b>
                                                        {response.country ===
                                                        'CA'
                                                            ? 'Postal Code'
                                                            : 'ZIP Code'}
                                                        :
                                                    </b>{' '}
                                                    {response.postcode}
                                                </div>
                                            )}
                                            {response.suburb && (
                                                <div className="addressLine">
                                                    <b>Suburb:</b>{' '}
                                                    {response.suburb}
                                                </div>
                                            )}
                                            {response.city_district && (
                                                <div className="addressLine">
                                                    <b>City District:</b>{' '}
                                                    {response.city_district}
                                                </div>
                                            )}
                                            {response.city && (
                                                <div className="addressLine">
                                                    <b>City:</b> {response.city}
                                                </div>
                                            )}
                                            {response.suburb && (
                                                <div className="addressLine">
                                                    <b>Suburb:</b>{' '}
                                                    {response.suburb}
                                                </div>
                                            )}
                                            {response.island && (
                                                <div className="addressLine">
                                                    <b>Island:</b>{' '}
                                                    {response.island}
                                                </div>
                                            )}
                                            {response.state_district && (
                                                <div className="addressLine">
                                                    <b>State District:</b>{' '}
                                                    {response.state_district}
                                                </div>
                                            )}
                                            {response.state && (
                                                <div className="addressLine">
                                                    <b>
                                                        {response.country ===
                                                        'CA'
                                                            ? 'Province'
                                                            : 'State'}
                                                        :
                                                    </b>{' '}
                                                    {response.state}
                                                </div>
                                            )}
                                            {response.country_region && (
                                                <div className="addressLine">
                                                    <b>Country:</b>{' '}
                                                    {response.country_region}
                                                </div>
                                            )}
                                            {response.country && (
                                                <div className="addressLine">
                                                    <b>Country:</b>{' '}
                                                    {countryCodeDisplayName(
                                                        response.country
                                                    )}
                                                </div>
                                            )}
                                            {response.world_region && (
                                                <div className="addressLine">
                                                    <b>World Region:</b>{' '}
                                                    {response.world_region}
                                                </div>
                                            )}
                                        </div>
                                    ) : (
                                        'Address could not be parsed'
                                    )}
                                </div>
                            </div>
                        ) : (
                            'Enter an address to parse.'
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};
