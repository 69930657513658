import { FlashMessageContext } from '../context';
import { useContext } from 'react';

const SERVER_ADDRESS = process.env.REACT_APP_API_URL;

const CONTENT_TYPE_HEADER = 'Content-Type';
const CONTENT_TYPE_JSON = 'application/json';
const CONTENT_TYPE_JSON_WITH_ENCODING = 'application/json; charset=utf-8';

const defaultHeaders = {};
defaultHeaders[CONTENT_TYPE_HEADER] = CONTENT_TYPE_JSON;

const AUTHORIZATION_HEADER = 'Authorization';

const formatResponse = (response) => {
    let dataType = response.headers.get(CONTENT_TYPE_HEADER);
    switch (dataType) {
        case CONTENT_TYPE_JSON:
        case CONTENT_TYPE_JSON_WITH_ENCODING:
            return response.json();
        default:
            return response.text();
    }
};

export const appendParamsToUrl = (url, params) => {
    if (!params) {
        return url;
    }
    let esc = encodeURIComponent;
    let query = Object.keys(params)
        .map((k) => {
            if (Array.isArray(params[k])) {
                return params[k]
                    .map((val, index) => {
                        return esc(k) + `[${index}]=` + esc(val);
                    })
                    .join('&');
            }
            return esc(k) + '=' + esc(params[k]);
        })
        .join('&');

    return url + (query ? '?' + query : '');
};

class HTTPError extends Error {
    constructor(statusCode, message) {
        super(message);
        this.statusCode = statusCode;
    }
}

export const request = async (
    apiUrl = '',
    method = 'get',
    body = {},
    config = {},
    messageDispatch = undefined,
    isLive = false
) => {
    if (!config) {
        config = {};
    }

    let url = (config.base ? config.base : SERVER_ADDRESS) + apiUrl;
    // send base url as base in config if you don't want to send request to our default server

    if (!method) {
        throw new Error('Http method needs to be defined');
    }

    let headersToSend = defaultHeaders;
    if (config.headers) {
        headersToSend = Object.assign({}, defaultHeaders, config.headers);
    }
    if (config.token) {
        headersToSend[AUTHORIZATION_HEADER] = config.token;
    }
    if (isLive) {
        headersToSend['X-LIVE'] = true;
    }

    let cachePolicy = config.cachePolicy || 'no-cache';

    var options = {
        method: method.toUpperCase(),
        headers: headersToSend,
        cache: cachePolicy,
    };

    if (config.abortController) {
        options.signal = config.abortController.signal;
    }

    switch (method.toLowerCase()) {
        case 'post':
        case 'patch':
        case 'put':
            body =
                headersToSend[CONTENT_TYPE_HEADER] !== CONTENT_TYPE_JSON
                    ? body
                    : JSON.stringify(body);
            options.body = body;
            break;
        case 'get':
        case 'delete':
            url = appendParamsToUrl(url, body);
            break;
        default:
    }

    if (!headersToSend[CONTENT_TYPE_HEADER]) {
        delete headersToSend[CONTENT_TYPE_HEADER];
    }

    const response = await fetch(url, options).catch((error) => {
        if (error.code === 20 || error.name === 'AbortError') {
            throw new Error('Abort Error');
        }
    });

    const statusCode = response.status;
    const resp = await formatResponse(response);

    // TODO Create custom error type that includes error code
    if (statusCode !== 200) {
        if (messageDispatch) {
            messageDispatch({
                type: 'error',
                payload: {
                    message: resp.error,
                },
            });
        }
        throw new HTTPError(statusCode, resp.message);
    }

    return resp;
};

export const useRequest = () => {
    const { messageDispatch } = useContext(FlashMessageContext);
    return async (apiUrl, method, body, config = {}) => {
        return await request(apiUrl, method, body, config, messageDispatch);
    };
};
