import React from 'react';
import { MESSAGE_TYPES, DEFAULT_FLASH_MSSAGE_INTERVAL } from './constants';
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

export const FlashMessageContext = React.createContext();

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const initialState = {
    open: false,
    message: null,
    type: MESSAGE_TYPES.INFORMATION,
    duration: DEFAULT_FLASH_MSSAGE_INTERVAL,
};

const reducer = (state, action) => {
    let { message, duration } = action.payload;
    if (!duration) {
        duration = DEFAULT_FLASH_MSSAGE_INTERVAL;
    }
    switch (action.type) {
        case 'success':
            return {
                ...state,
                open: true,
                message: message,
                type: MESSAGE_TYPES.SUCCESS,
                duration: duration,
            };
        case 'error':
            return {
                ...state,
                open: true,
                message: message,
                type: MESSAGE_TYPES.ERROR,
                duration: duration,
            };

        case 'warning':
            return {
                ...state,
                open: true,
                message: message,
                type: MESSAGE_TYPES.WARNING,
                duration: duration,
            };

        case 'info':
            return {
                ...state,
                open: true,
                message: message,
                type: MESSAGE_TYPES.INFORMATION,
                duration: duration,
            };

        case 'hide':
            return initialState;

        default:
            return state;
    }
};

export const FlashMessageContextProvider = (props) => {
    const [messageState, messageDispatch] = React.useReducer(
        reducer,
        initialState
    );
    const handleClose = (ev, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        messageDispatch({
            type: 'hide',
            payload: {},
        });
    };
    return (
        <FlashMessageContext.Provider
            value={{
                messageState,
                messageDispatch,
            }}
        >
            <FlashMessageContext.Consumer>
                {({ messageState }) => {
                    return !messageState.message || !messageState.open ? (
                        props.children
                    ) : (
                        <>
                            {props.children}
                            <Snackbar
                                open={messageState.open}
                                autoHideDuration={messageState.duration}
                                onClose={handleClose}
                            >
                                <Alert
                                    onClose={handleClose}
                                    severity={messageState.type}
                                >
                                    {messageState.message}
                                </Alert>
                            </Snackbar>
                        </>
                    );
                }}
            </FlashMessageContext.Consumer>
        </FlashMessageContext.Provider>
    );
};
