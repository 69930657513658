export class LocalDate {
    static toLocalDateReadable = (date) => {
        if (!date) {
            return null;
        }
        return new Date(date).toDateString();
    };

    static toLocalDateTimeReadable = (date) => {
        if (!date) {
            return null;
        }
        return new Date(date).toString().split('GMT')[0];
    };
}
