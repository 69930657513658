export const completions = async (request, params = {}) => {
    return await request('/completions', 'post', params);
};

export const parseAddressToString = (option) => {
    return `${option.address ? `${option.address} ` : ''}${
        option.city ? `${option.city} ` : ''
    }${option.prov ? `${option.prov} ` : ''}${
        option.pc ? `${option.pc} ` : ''
    }`;
};
