import React, { useState, useEffect, createRef } from 'react';
import PropTypes from 'prop-types';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField, CircularProgress, makeStyles } from '@material-ui/core';
import { useDebounce } from './use-debounce';
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles((theme) => ({
    paper: {
        margin: 0,
        color: '#586069',
        fontSize: 13,
    },
    option: {
        minHeight: 'auto',
        alignItems: 'flex-start',
        padding: theme.spacing(1),
        fontSize: 13,
        '&[aria-selected="true"]': {
            background: '#296de8',
            backgroundImage: 'linear-gradient(to top, #4d8afb, #2569e6)',
            color: '#fff',
        },
        '&[data-focus="true"]': {
            background: '#296de8',
            backgroundImage: 'linear-gradient(to top, #4d8afb, #2569e6)',
            color: '#fff',
        },
    },
    boxWrapper: {
        marginTop: 5,
        boxShadow: '0px 1px 2px 0 rgba(0, 0, 0, 0.08)',
        width: '100%',
        zIndex: 1,
        background: '#f8fafa',
        color: '#2f3a4e',
        border: '1px solid #e4e8ea',
        '&>*>.MuiFormControl-root': {
            borderBottom: 'none',
        },
        '&>*>*>.MuiInput-underline:hover:not(.Mui-disabled):before': {
            border: 'none',
        },
    },
    tagWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    tag: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'nowrap',
        background: '#296de8',
        color: '#fff',
        margin: theme.spacing(0.5, 1, 0.5, 0),
        padding: theme.spacing(0.25, 1),
        borderRadius: '3px',
        fontSize: 14,
    },
    inputBase: {
        borderBottom: '1px solid #2f3a4e',
        transition: 'none',
        '&>.MuiInputBase-root': {
            paddingLeft: theme.spacing(1),
        },
        '&>.MuiInput-underline:before': {
            transition: 'none',
            borderBottom: 'none',
        },
        '&>.MuiInput-underline:after': {
            borderBottom: '0',
            transition: 'none',
        },
    },
    clearIcon: {
        marginLeft: theme.spacing(0.5),
        cursor: 'pointer',
    },
    popper: {
        marginTop: '2px',
    },
}));

export const AutoCompleteSearchBox = (props) => {
    const classes = useStyles();
    const {
        value,
        setValue,
        inputValue,
        setInputValue,
        optionsRequest,
        abortController,
        parseResult,
        multiple,
        getOptionLabel,
        autoCompleteLabel,
        renderOption,
        disabled,
    } = props;
    const textFieldRef = createRef();
    const [options, setOptions] = useState([]);
    const [loaderEnabledSearch, showLoaderSearch] = useState(false);

    if (textFieldRef && textFieldRef.current) {
        textFieldRef.current.focus();
    }

    const debouncedInputValue = useDebounce(inputValue, 100);

    useEffect(() => {
        let active = true;
        (async () => {
            try {
                if (abortController) {
                    showLoaderSearch(true);
                }
                let results = await optionsRequest();
                if (parseResult) {
                    results = parseResult(results);
                }
                if (active) {
                    setOptions(results || []);
                }
                if (abortController) {
                    showLoaderSearch(false);
                }
            } catch (err) {
                if (err.message === 'Abort Error') {
                    return undefined;
                }
            }
        })();

        return () => {
            active = false;
            if (abortController) {
                abortController.abort();
            }
        };
    }, [debouncedInputValue]);

    const deleteValue = (val) => {
        if (multiple) {
            let newValues = value.slice();
            setValue(newValues.filter((elem) => elem !== val));
        } else {
            setValue('');
        }
    };

    return (
        <div
            className={classes.boxWrapper}
            style={{ width: props.width || '100%' }}
        >
            <Autocomplete
                classes={{
                    paper: classes.paper,
                    option: classes.option,
                    popper: classes.popper,
                }}
                disabled={disabled}
                renderTags={(value) => {
                    if (multiple) {
                        return (
                            <div className={classes.tagWrapper}>
                                {value.map((val) => {
                                    return (
                                        <div className={classes.tag}>
                                            <div>
                                                {getOptionLabel
                                                    ? getOptionLabel(val)
                                                    : val}
                                            </div>
                                            <ClearIcon
                                                onClick={() => {
                                                    deleteValue(val);
                                                }}
                                                className={classes.clearIcon}
                                                fontSize={'small'}
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    }
                    return (
                        <div
                            onClick={() => {
                                deleteValue();
                            }}
                        >
                            {getOptionLabel ? getOptionLabel(value) : value}
                        </div>
                    );
                }}
                multiple={multiple}
                options={options}
                autoComplete
                includeInputInList
                value={value}
                renderOption={renderOption}
                filterOptions={(options) => options}
                onChange={(event, newValue) => {
                    if (newValue) {
                        // setOptions([]);
                        setValue(newValue);
                    }
                }}
                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                }}
                getOptionLabel={getOptionLabel}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        placeholder={autoCompleteLabel}
                        fullWidth
                        ref={textFieldRef}
                        className={classes.inputBase}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {loaderEnabledSearch ? (
                                        <CircularProgress
                                            color="inherit"
                                            size={20}
                                        />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                        }}
                    />
                )}
            />
        </div>
    );
};

AutoCompleteSearchBox.propTypes = {
    optionsRequest: PropTypes.func,
    value: PropTypes.any,
    setValue: PropTypes.func,
    inputValue: PropTypes.any,
    setInputValue: PropTypes.func,
    parseResult: PropTypes.func,
    multiple: PropTypes.bool,
};

AutoCompleteSearchBox.defaultProps = {
    multiple: false,
};
