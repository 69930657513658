import React from 'react';
import Verify from '../verify';
import Autocomplete from '../autocomplete';
import Suggest from '../suggest';
import Parse from '../parse';

export const routes = [
    {
        key: 'Autocomplete',
        path: ['/autocomplete'],
        children: <Autocomplete />,
    },
    {
        key: 'Suggest',
        path: ['/suggest'],
        children: <Suggest />,
    },
    {
        key: 'Parse',
        path: ['/parse'],
        children: <Parse />,
    },
    {
        key: 'Verify',
        path: ['/', '/verify'],
        children: <Verify />,
    },
];
