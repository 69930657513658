import React from 'react';
import { useContext, useEffect } from 'react';
import { Switch, Route, BrowserRouter, useParams } from 'react-router-dom';
import {
    FlashMessageContextProvider,
    LoaderContextProvider,
    CountryContext,
    CountryContextProvider,
} from './context';
import { routes } from './routes';
import { useLocation } from './common';

import logo from './pg-logo.png';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    logoWrapper: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    logo: {
        maxWidth: '30vh',
        height: '100%',
        flex: 1,
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
        },
        margin: theme.spacing(1),
    },
}));

function Logo() {
    const classes = useStyles();
    return (
        <div className={classes.logoWrapper}>
            <img className={classes.logo} src={logo} alt="Logo" />
        </div>
    );
}

function App() {
    return (
        <CountryContextProvider>
            <FlashMessageContextProvider>
                <LoaderContextProvider>
                    <BrowserRouter>
                        <Switch>
                            {routes.map((route) => {
                                return (
                                    <Route path={route.path} key={route.key}>
					    {route.children}
                                    </Route>
                                );
                            })}
                        </Switch>
                    </BrowserRouter>
                </LoaderContextProvider>
            </FlashMessageContextProvider>
        </CountryContextProvider>
    );
}

export default App;
