import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export { AutoCompleteSearchBox } from './autocomplete-search';
export { LocalDate } from './date-time';
export { request, useRequest, appendParamsToUrl } from './http';
export { useLoader } from './use-loader';
export { default as useRouter } from './use-router';
export { StructedAddress } from './address';
export { getCommonFormClasses } from './classes';
export { validCodeToStatus, errorCodesToMessages } from './parseResponse';

export const capitalize = (str, lower = false) => {
    if (!str) return str;
    return (lower ? str.toLowerCase() : str).replace(
        /(?:^|\s|["'([{])+\S/g,
        (match) => match.toUpperCase()
    );
};

export const countryMapping = {
    ca: {
        name: 'Canada',
    },
    us: {
        name: 'United States',
    },
};

export const sampleAddresses = {
    us: {
        structured: {
            country: 'us',
            address: '145 Mulberry St',
            address2: '',
            city: 'New York',
            prov: 'New York',
        },
        freeform: '145 Mulberry St, New York NY 10013',
    },
    ca: {
        structured: {
            country: 'ca',
            address: '414-20 Bay Street',
            address2: '11th Floor',
            city: 'Toronto',
            prov: 'Ontario',
        },
        freeform: '20 Bay Street Floor 11, Toronto ON',
    },
};

export const countryCodeDisplayName = (country) => {
    if (!country) {
        return null;
    }
    country = country.toLowerCase();
    if (!countryMapping[country]) {
        return country;
    } else {
        return countryMapping[country].name;
    }
};

export const formatDateToString = (date) => {
    if (date instanceof Date && isNaN(date)) {
        return new Date().toISOString().split('T')[0];
    }
    return date.toISOString().split('T')[0];
};

// From https://reactrouter.com/web/example/query-parameters
// A custom hook that builds on useLocation to parse
// the query string for you.
export const useQuery = () => {
    const { search } = useLocation();

    return useMemo(() => {
        return new URLSearchParams(search);
    }, [search]);
};
