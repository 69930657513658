import { Button, makeStyles, MenuItem, Select } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import {
    errorCodesToMessages,
    getCommonFormClasses,
    StructedAddress,
    useLoader,
    useRequest,
    validCodeToStatus,
    sampleAddresses,
    useQuery,
} from '../common';
import { FreeFormAddress } from '../common/address';
import { FlashMessageContext } from '../context';
import * as services from './services';
import TopNav from '../top-nav';

const useStyles = makeStyles((theme) => ({
    flexWrapper: {
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    flexItem: {
        flexGrow: 1,
        width: '100%',
    },
    ...getCommonFormClasses(theme),
}));

export default () => {
    const classes = useStyles();
    const query = useQuery();
    const [mode, updateMode] = useState('structured');
    const [address, setAddress] = useState({});
    const [response, setResponse] = useState(null);
    const { messageDispatch } = useContext(FlashMessageContext);
    const [inputAddress, setInputAddress] = useState('');

    const loader = useLoader();
    const request = useRequest();

    useEffect(() => {
        if (mode === 'structured') {
            setAddress({ country: query.get('country') });
        } else {
            setAddress('');
        }
    }, [query, mode]);

    const verifyAddress = async () => {
        if (mode === 'structured' && !address.address) {
            messageDispatch({
                type: 'error',
                payload: {
                    message: 'Address Line 1 is required',
                },
            });
            return;
        } else if (mode === 'freeform' && !address) {
            messageDispatch({
                type: 'error',
                payload: {
                    message: 'Address value is required',
                },
            });
            return;
        }
        const res = await loader(
            services.verify(
                request,
                mode === 'structured' ? address : { address }
            )
        );
        if (mode === 'structured') {
            setInputAddress(services.parseInputAddressToString(address));
        } else {
            setInputAddress(address);
        }
        setResponse(res);
    };

    return (
        <>
            <TopNav />
            <div className={classes.flexWrapper}>
                <div className={classes.flexItem}>
                    <div className={classes.paddingLarge}>
                        <div className={classes.marginBottomLarge}>
                            Validate and correct addresses across US and Canada.
                        </div>
                        <div className={classes.formItemWrapper}>
                            <div className={classes.label}>Type</div>
                            <div className={classes.inputWrapper}>
                                <Select
                                    className={classes.select}
                                    value={mode}
                                    onChange={(ev) => {
                                        updateMode(ev.target.value);
                                    }}
                                    variant="outlined"
                                >
                                    <MenuItem value={'structured'}>
                                        Structured
                                    </MenuItem>
                                    <MenuItem value={'freeform'}>
                                        Free Form
                                    </MenuItem>
                                </Select>
                            </div>
                        </div>
                        {mode === 'structured' ? (
                            <StructedAddress
                                address={address}
                                setAddress={setAddress}
                                itemWrapperClass={classes.formItemWrapper}
                                labelClass={classes.label}
                                inputWrapperClass={classes.inputWrapper}
                                selectClass={classes.select}
                                inputClass={classes.input}
                            />
                        ) : (
                            <FreeFormAddress
                                address={address}
                                setAddress={setAddress}
                                itemWrapperClass={classes.formItemWrapper}
                                labelClass={classes.label}
                                inputWrapperClass={classes.inputWrapper}
                                inputClass={classes.input}
                            />
                        )}
                        <div className={classes.textCenter}>
                            <Button
                                className={classes.secondaryButton}
                                onClick={() => {
                                    setAddress(
                                        sampleAddresses[
                                            address.country || 'us'
                                        ][mode]
                                    );
                                }}
                            >
                                Sample
                            </Button>
                            <Button
                                className={classes.primaryButton}
                                onClick={() => {
                                    verifyAddress();
                                }}
                            >
                                Verify
                            </Button>
                        </div>
                    </div>
                </div>
                <div className={classes.flexItem}>
                    <div
                        className={`${classes.rightWrapper}  ${classes.paddingLarge}`}
                    >
                        {response ? (
                            <div>
                                <div className={classes.boxWrapper}>
                                    <div className="heading">Entered</div>
                                    <div className="subText">
                                        {inputAddress}
                                    </div>
                                </div>
                                <div className={classes.boxWrapper}>
                                    <div className="heading">Result</div>
                                    <div className="subText">
                                        {['C', 'V'].includes(
                                            response.valid
                                        ) && (
                                            <div className={'address'}>
                                                <p>{response.address}</p>
                                                {response.address2 && (
                                                    <p>{response.address2}</p>
                                                )}
                                                {response.city && (
                                                    <p>
                                                        {response.city}
                                                        {response.provState && (
                                                            <span>
                                                                {' '}
                                                                {
                                                                    response.provState
                                                                }
                                                            </span>
                                                        )}
                                                        {response.postalZip && (
                                                            <span>
                                                                {' '}
                                                                {
                                                                    response.postalZip
                                                                }
                                                                {response.us_zipPlus4
                                                                    ? `-${response.us_zipPlus4}`
                                                                    : ''}
                                                            </span>
                                                        )}
                                                    </p>
                                                )}
                                                {response.country && (
                                                    <p>{response.country}</p>
                                                )}
                                            </div>
                                        )}
                                        <div
                                            className={`status ${validCodeToStatus(
                                                response.valid
                                            )}`}
                                        >
                                            {validCodeToStatus(response.valid)}
                                        </div>
                                        {response.ca_correct ? (
                                            <ul>
                                                {errorCodesToMessages(
                                                    response.ca_correct
                                                ).map((res) => {
                                                    return (
                                                        <li key={res.key}>
                                                            {res.value}
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        ) : (
                            'Enter an address in the form to verify.'
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};
